let manandvanlocation = [
  "Bromley",
  "Croydon",
  "Dartford",
  "East-Central-London",
  "East-London",
  "Enfield",
  "Harrow",
  "Ilford",
  "Kingston",
  "North-London",
  "North-West-London",
  "Romford",
  "South-East-London",
  "South-West-London",
  "Surrey",
  "Twickenham",
  "Uxbridge",
  "Watford",
  "West-Central-London",
  "West-London",
  "Abbey Wood",
  "Abbots Langley",
  "Acton",
  "Acton Central",
  "Acton Town",
  "Addington Village",
  "Addlestone",
  "Aldgate",
  "Alexandra Palace",
  "All Saints",
  "Alperton",
  "Ampere",
  "Anerley",
  "Angel",
  "Archway",
  "Arena",
  "Arnos Grove",
  "Arsenal",
  "Ashtead",
  "Baker Street",
  "Balham",
  "Bank",
  "Bankside",
  "Barbican",
  "Barking",
  "Barkingside",
  "Barnehurst",
  "Barnes",
  "Barnet",
  "Barnsbury",
  "Barons Court",
  "Battersea",
  "Bayswater",
  "Beckenham",
  "Beckton",
  "Becontree",
  "Belgravia",
  "Bellingham",
  "Belsize Park",
  "Belvedere",
  "Bermondsey",
  "Berrylands",
  "Bethnal Green",
  "Bexley",
  "Bexleyheath",
  "Bickley",
  "Birkbeck",
  "Blackfriars",
  "Blackheath",
  "Blackhorse",
  "Blackhorse Road",
  "Blackwall",
  "Bloomsbury",
  "Bond Street",
  "Borehamwood",
  "Borough",
  "Borough Market",
  "Boston Manor",
  "Bounds Green",
  "Bow",
  "Bow Church",
  "Bow Road",
  "Brent Cross",
  "Brentford",
  "Brimsdown",
  "Brixton",
  "Brockley",
  "Bromley",
  "Bromley-by-Bow",
  "Brompton",
  "Brondesbury",
  "Brondesbury Park",
  "Bruce Grove",
  "Buckhurst",
  "Buckhurst Hill",
  "Burnt Oak",
  "Bushey",
  "Byfleet",
  "Cadogan Pier",
  "Caledonian Road",
  "Camberwell",
  "Cambridge Heath",
  "Camden Town",
  "Canada Water",
  "Canary Wharf",
  "Canning",
  "Canning Town",
  "Cannon",
  "Cannon Street",
  "Canonbury",
  "Canons Park",
  "Carerham",
  "Carpenders Park",
  "Carshalton Beeches",
  "Castelnau",
  "Caterham",
  "Catford",
  "Chadwell Heath",
  "Chalk Farm",
  "Chancery Lane",
  "Charing Cross",
  "Charlton",
  "Cheam",
  "Chelsea",
  "Chertsey",
  "Chessington",
  "Chigwell",
  "Chingford",
  "Chislehurst",
  "Chiswick",
  "Chorleywood",
  "Clapham",
  "Clapham Junction",
  "Clapton",
  "Clerkenwell",
  "Cobham",
  "Cockfosters",
  "Colindale",
  "Colliers Wood",
  "Coombe Lane",
  "Cottenham Park",
  "Coulsdon",
  "Covent Garden",
  "Crayford",
  "Cricklewood",
  "Crofton",
  "Crofton Park",
  "Crossharbour",
  "Crouch End",
  "Crouch Hill",
  "Croxley",
  "Croydon",
  "Crystal Palace",
  "Dagenham",
  "Dalston",
  "Dalston Junction",
  "Dalston Kingsland",
  "Dartford",
  "Debden",
  "Deptford",
  "Devons Road",
  "Docklands",
  "Dollis Hill",
  "Drayton",
  "Dulwich",
  "Ealing",
  "Ealing Broadway",
  "Ealing Common",
  "Earls Court",
  "Earlsfield",
  "East Acton",
  "East Dulwich",
  "East Finchley",
  "East Ham",
  "East India",
  "East Putney",
  "East Sheen",
  "Eastcote",
  "Eden Park",
  "Edgware",
  "Edgware Road",
  "Edmonton",
  "Elephant and Castle",
  "Elm Park",
  "Elmers End",
  "Elmstead Woods",
  "Eltham",
  "Elverson",
  "Embankment",
  "Enfield",
  "Enfield Chase",
  "Enfield Town",
  "Epsom",
  "Erith",
  "Esher",
  "Euston",
  "Ewell",
  "Fairlop",
  "Falconwood",
  "Farringdon",
  "Feltham",
  "Fenchurch",
  "Fieldway Stop",
  "Finchley",
  "Finchley Central",
  "Finchley Road",
  "Finsbury",
  "Finsbury Park",
  "Fleet Street",
  "Forest Gate",
  "Forest Hill",
  "Friern Barnet",
  "Frognal",
  "Fulham",
  "Fulham Broadway",
  "Gallions Reach",
  "Gants",
  "Gipsy Hill",
  "Gloucester",
  "Gloucester Road",
  "Golders Green",
  "Goldhawk",
  "Goodge Street",
  "Goodmayes",
  "Gordon rd",
  "Gospel Oak",
  "Grange Hill",
  "Grays",
  "Grays Inn",
  "Great London",
  "Great Portland",
  "Greater London",
  "Green Lanes",
  "Green Park",
  "Greenford",
  "Greenwich",
  "Grove Park",
  "Gunnersbury",
  "Hackney",
  "Hackney Central",
  "Hackney Downs",
  "Hackney Wick",
  "Hadley Wood",
  "Haggerston",
  "Hainault",
  "Ham",
  "Hammersmith",
  "Hampstead",
  "Hampstead Heath",
  "Hampton",
  "Hampton Court",
  "Hampton Wick",
  "Hanger Lane",
  "Hanwell",
  "Harefield",
  "Harlesden",
  "Harringay",
  "Harringay Lanes",
  "Harrow",
  "Harrow On The Hill",
  "Harrow Weald",
  "Hatch End",
  "Hatton",
  "Hatton Cross",
  "Haydons",
  "Hayes",
  "Headstone Lane",
  "Heathrow",
  "Heathrow Airport",
  "Hendon",
  "Hendon Central",
  "Herne Hill",
  "Heron Quays",
  "Hertfordshire",
  "Heston",
  "High Barnet",
  "High Street Kensington",
  "Highams",
  "Highams Park",
  "Highbury",
  "Highgate",
  "Hillingdon",
  "Hither",
  "Hither Green",
  "Holborn",
  "Holland Park",
  "Holloway",
  "Holloway Road",
  "Homerton",
  "Honor Oak Park",
  "Hornchurch",
  "Hornsey",
  "Hounslow",
  "Hoxton",
  "Hyde Park Corner",
  "Ickenham",
  "Ilford",
  "Imperial Wharf",
  "Island Gardens",
  "Isle of Dogs",
  "Isleworth",
  "Islington",
  "Kenley",
  "Kennington",
  "Kensal Green",
  "Kensal Rise",
  "Kensington",
  "Kensington Olympia",
  "Kent House",
  "Kentish Town",
  "Kenton",
  "Keston",
  "Kew",
  "Kidbrooke",
  "Kilburn",
  "King George V",
  "Kings Cross",
  "Kings Langley",
  "Kingsbury",
  "Kingston",
  "Kingston Town",
  "Knightsbridge",
  "Ladbroke Grove",
  "Ladywell",
  "Lambeth",
  "Lambeth North",
  "Lancaster Gate",
  "Latimer Road",
  "Leatherhead",
  "Lee",
  "Leicester Square",
  "Lewisham",
  "Leyton",
  "Leytonstone",
  "Liverpool Street",
  "London Bridge",
  "London City",
  "London Fields",
  "London",
  "Loughborough Junction",
  "Loughton",
  "Lower Edmonton",
  "Lower Sydenham",
  "Maida Vale",
  "Malden",
  "Malden Manor",
  "Manor House",
  "Manor Park",
  "Marble Arch",
  "Marylebone",
  "Marylebone Road",
  "Mayfair",
  "Merton",
  "Mile End",
  "Mill Hill",
  "Millwall",
  "Mitcham",
  "Monument",
  "Moor Park",
  "Moorgate",
  "Morden",
  "Mornington Crescent",
  "Mortlake",
  "Motspur",
  "Mottingham",
  "Mudchute",
  "Muswell Hill",
  "Neasden",
  "New Barnet",
  "New Beckenham",
  "New Cross",
  "New Eltham",
  "New Malden",
  "New Southgate",
  "Newbury",
  "Nine Elms",
  "Norbiton",
  "Norbury",
  "North Acton",
  "North Dulwich",
  "North Ealing",
  "North Finchley",
  "North Greenwich",
  "North Harrow",
  "North Kensington",
  "North Wembley",
  "North Woolwich",
  "Northfields",
  "Northolt",
  "Northwick Park",
  "Northwood",
  "Northwood Junction",
  "Norwood Green",
  "Notting Hill",
  "Nunhead",
  "Old Street",
  "Orpington",
  "Osterley",
  "Oval",
  "Oxford Circus",
  "Paddington",
  "Palmers Green",
  "Park Royal",
  "Parsons Green",
  "Peckham",
  "Peckham Rye",
  "Penge",
  "Perivale",
  "Piccadilly Circus",
  "Pimlico",
  "Pinner",
  "Plaistow",
  "Plumstead",
  "Ponders End",
  "Pontoon Dock",
  "Poplar",
  "Preston Road",
  "Pudding Mill Lane",
  "Purfleet",
  "Purley",
  "Queens Park",
  "Queensway",
  "Radlett",
  "Rainham",
  "Ravenscourt Park",
  "Rayners",
  "Rayners Lane",
  "Raynes Park",
  "Regents Park",
  "Regents Street",
  "Richmond",
  "Rickmansworth",
  "Roding Valley",
  "Roehampton",
  "Romford",
  "Rotherhithe",
  "Royal Albert",
  "Royal Arsenal",
  "Royal Oak",
  "Royal Victoria",
  "Ruislip",
  "Russell Square",
  "Sanderstead",
  "Selhurst",
  "Seven Kings",
  "Seven Sisters",
  "Shadwell",
  "Shepherds Bush",
  "Shepperton",
  "Shoreditch",
  "Shortlands",
  "Sidcup",
  "Silver Street",
  "Sloane Square",
  "Soho",
  "South Acton",
  "South Bank",
  "South Bermonsey",
  "South Croydon",
  "South Ealing",
  "South Greenford",
  "South Hampstead",
  "South Harrow",
  "South Kensington",
  "South Kenton",
  "South Lambeth",
  "South Merton",
  "South Norwood",
  "South Ockendon",
  "South Quay",
  "South Ruislip",
  "South Tottenham",
  "South Wimbledon",
  "South Woodford",
  "Southall",
  "Southfields",
  "Southgate",
  "Southwark",
  "St James Park",
  "St James Street",
  "St Johns Wood",
  "St Margarets",
  "St Mary Cray",
  "St Pancras",
  "St Pauls",
  "Stamford Brook",
  "Stamford Hill",
  "Stanmore",
  "Stepney",
  "Stockwell",
  "Stoke Newington",
  "Stonebridge Park",
  "Stoneleigh",
  "Strand",
  "Stratford",
  "Strawberry Hill",
  "Streatham",
  "Streatham Common",
  "Sudbury",
  "Sudbury Hill",
  "Sundridge Park",
  "Surbiton",
  "Surrey Docks",
  "Surrey Quays",
  "Sutton",
  "Sutton Common",
  "Swiss Cottage",
  "Sydenham",
  "Sydenham Hill",
  "Syon Lane",
  "Teddington",
  "Temple",
  "Thames Ditton",
  "Thamesmead",
  "Thornton Heath",
  "Tilbury",
  "Tolworth",
  "Tooting",
  "Tooting Bec",
  "Tooting Broadway",
  "Tottenham",
  "Tottenham Court",
  "Tottenham Court Road",
  "Totteridge",
  "Tower Gateway",
  "Tower Hill",
  "Tufnell Park",
  "Tulse Hill",
  "Turnpike Lane",
  "Twickenham",
  "Upminster",
  "Upminster Bridge",
  "Upney",
  "Upper Edmonton",
  "Upper Halliford",
  "Upper Holloway",
  "Upper Norwood",
  "Upton Park",
  "Uxbridge",
  "Vauxhall",
  "Victoria",
  "Waddon",
  "Wallington",
  "Walthamstow",
  "Walthamstow Central",
  "Walton On Thames",
  "Walworth",
  "Wandle Park",
  "Wandsworth",
  "Wanstead Park",
  "Wapping",
  "Warren Street",
  "Warwick Avenue",
  "Waterloo",
  "Waterloo East",
  "Watford",
  "Wealdstone",
  "Wellesley",
  "Welling",
  "Wembley",
  "Wembley Central",
  "Wembley Park",
  "Wembley Stadium",
  "West Acton",
  "West Brompton",
  "West Croydon",
  "West Drayton",
  "West Dulwich",
  "West Ealing",
  "West Finchley",
  "West Ham",
  "West Hampstead",
  "West Harrow",
  "West Kensington",
  "West Norwood",
  "West Silvertown",
  "West Wickham",
  "Westbourne Park",
  "Westcombe Park",
  "Westminster",
  "Weybridge",
  "Whetstone",
  "White City",
  "White Hartlane",
  "Whitechapel",
  "Whitton",
  "Willesden",
  "Willesden Green",
  "Willesden Junction",
  "Wimbledon",
  "Wimbledon Chase",
  "Winchmore Hill",
  "Winchmore Hill",
  "Wood Green",
  "Wood Street",
  "Woodford",
  "Woodford Green",
  "Woodside Park",
  "Woolwich",
  "Woolwich Arsenal",
  "Worcester Park",
];
export default manandvanlocation;
